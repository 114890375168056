import Cookies from "js-cookie";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOAD_ALL_USERS_SUCCESS,
  LOAD_ALL_USERS_FAIL,
  CHANGE_NAV,
  LOAD_USER_DETAILS_SUCCESS,
  LOAD_USER_DETAILS_FAIL,
} from "../actions/types";

// const user = JSON.parse(localStorage.getItem("user"));
const user = JSON.parse(Cookies.get("auth-token") || null);

const initialState = user
  ? {
      userlist: null,
      isLoggedIn: true,
      user: user,
      activeNav: window.location.pathname,
      userDetails: null,
    }
  : {
      userlist: null,
      isLoggedIn: false,
      user: null,
      activeNav: window.location.pathname,
      userDetails: null,
    };

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: payload.user.success,
        user: payload.user.data,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: payload.user.success,
        user: payload.user.data,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOAD_ALL_USERS_SUCCESS:
      return {
        ...state,
        userlist: payload.data,
      };
    case LOGOUT:
      return {
        ...state,
        userlist: [],
        user: null,
        isLoggedIn: false,
        userDetails: null,
      };
    case CHANGE_NAV:
      return {
        ...state,
        activeNav: payload,
      };
    case LOAD_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: payload.data,
      };
    case LOAD_USER_DETAILS_FAIL:
      return {
        ...state,
        userDetails: null,
      };
    default:
      return state;
  }
}

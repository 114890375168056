/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import BaseUrl from "../util/BaseUrl";
import authHeader from "./auth-header";
import Cookies from "js-cookie";

const register = async (userDetails) => {
  return await fetch(BaseUrl + "register", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(userDetails),
  })
    .then((result) => result.json())
    .then((response) => {
      if (response.success) {
        // localStorage.setItem("user", JSON.stringify(response.data));
        Cookies.set("auth-token", JSON.stringify(response.data));
      }
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const login = async (userDetails) => {
  return await fetch(BaseUrl + "login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(userDetails),
  })
    .then((result) => result.json())
    .then((response) => {
      console.log("response ", response);
      if (response.success == true) {
        // localStorage.setItem("user", JSON.stringify(response.data));
        Cookies.set("auth-token", JSON.stringify(response.data));
      }
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const forgotpassword = (obj) => {
  return axios.post(BaseUrl + "forgot_password", obj, {
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    method: "post",
  });
};
const ResetPassword = (obj) => {
  return axios.post(BaseUrl + "reset-password", obj, {
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    method: "post",
  });
};

const loadNotifications = () => {
  // return axios.get(BaseUrl + "total_notifications_of_event", {
  return axios.get(BaseUrl + "total_notifications", {
    headers: authHeader(),
  });
};
const markAsReadNotification = (id) => {
  // return axios.get(BaseUrl + "total_notifications_of_event", {
  return axios.post(BaseUrl + "markasReadNotification", id, {
    headers: authHeader(),
    method: "post",
  });
};
const loadAllUsers = () => {
  return axios.get(BaseUrl + "get_all_users", { headers: authHeader() });
};
const getUserDetails = () => {
  return axios.get(BaseUrl + "user", { headers: authHeader() });
};

const getUserTimelineData = (id) => {
  return axios.get(BaseUrl + "get_timeline_post/" + id, {
    headers: authHeader(),
  });
};

const createUserTimelinePost = (data) => {
  return axios.post(BaseUrl + "create_timeline_post", data, {
    mode: "no-cors",
    method: "post",
    headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
  });
};
const updateUserTimelinePost = (id, data) => {
  return axios.post(BaseUrl + "update_timeline_post/" + id, data, {
    mode: "no-cors",
    method: "post",
    headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
  });
};
const updateUserDetails = (data) => {
  return axios.post(BaseUrl + "update_user_details", data, {
    mode: "no-cors",
    method: "post",
    headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
  });
};
const changePassword = (data) => {
  return axios.post(BaseUrl + "change_password", data, {
    mode: "no-cors",
    method: "post",
    headers: authHeader(),
  });
};
const updateTreeAdmins = (data) => {
  return axios.post(BaseUrl + "update_tree_admins", data, {
    mode: "no-cors",
    method: "post",
    headers: authHeader(),
  });
};

const logout = () => {
  axios.post(BaseUrl + "logout", {}, { headers: authHeader() }).then((res) => {
    if (res.data.success == true) {
      // localStorage.removeItem("user");
      Cookies.remove("auth-token");
      return true;
    } else {
      return false;
    }
  });
};

export default {
  register,
  login,
  logout,
  loadNotifications,
  loadAllUsers,
  getUserDetails,
  updateUserDetails,
  changePassword,
  getUserTimelineData,
  createUserTimelinePost,
  updateUserTimelinePost,
  markAsReadNotification,
  forgotpassword,
  ResetPassword,
  updateTreeAdmins,
};

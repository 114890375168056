import {
  LOAD_FAMILY_TREE_SUCCESS,
  LOAD_FAMILY_TREE_FAIL,
  INVITE_BY_USER_SUCCESS,
  INVITE_BY_USER_FAIL,
  JOINED_TREES_SUCCESS,
  JOINED_TREES_FAIL,
} from "../actions/types";

const initialState = {
  personalTree: null,
  invitesByUsers: null,
  joinedTrees: null,
  message: "",
  isLoading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_FAMILY_TREE_SUCCESS:
      return {
        ...state,
        personalTree: payload.data,
        message: payload.message,
        isLoading: false,
      };
    case LOAD_FAMILY_TREE_FAIL:
      return {
        ...state,
        personalTree: {},
        message: "Failed to load",
        isLoading: false,
      };
    case INVITE_BY_USER_SUCCESS:
      return {
        ...state,
        invitesByUsers: payload.data,
        message: payload.message,
        isLoading: false,
      };
    case INVITE_BY_USER_FAIL:
      return {
        ...state,
        invitesByUsers: [],
        message: "Failed to load",
        isLoading: false,
      };
    case JOINED_TREES_SUCCESS:
      return {
        ...state,
        joinedTrees: payload.data,
        message: payload.message,
        isLoading: false,
      };
    case JOINED_TREES_FAIL:
      return {
        ...state,
        joinedTrees: [],
        message: "Failed to load",
        isLoading: false,
      };
    default:
      return state;
  }
}

import axios from "axios";
import authHeader from "./auth-header";
import BaseUrl from "../util/BaseUrl";

const createTree = (data) => {
  return axios.post(BaseUrl + "create_tree", data, {
    method: "post",
    headers: authHeader(),
  });
};
const addFamilyMember = (data) => {
  return axios.post(BaseUrl + "add_family_members", data, {
    method: "post",
    headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
  });
};
const removeFamilyMember = (data) => {
  return axios.post(BaseUrl + "remove_family_members", data, {
    method: "post",
    headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
  });
};
const loadTree = () => {
  return axios.get(BaseUrl + "get_tree", {
    method: "get",
    headers: authHeader(),
  });
};
const loadInvitationsByUsers = () => {
  return axios.get(BaseUrl + "get_user_tree_invites", {
    method: "get",
    headers: authHeader(),
  });
};
const loadJoinedTreesByUsers = () => {
  return axios.get(BaseUrl + "get_joined_trees", {
    method: "get",
    headers: authHeader(),
  });
};
const updateTreeInviteStatus = (id, obj) => {
  return axios.post(BaseUrl + "tree_invite_status/" + id, obj, {
    mode: "no-cors",
    method: "post",
    headers: authHeader(),
  });
};
// const updateRecipyById = (id, formData, tags) => {
//   return axios.post(BaseUrl + "update_recipe/" + id, formData, {
//     mode: "no-cors",
//     method: "post",
//     headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
//     body: JSON.stringify(tags),
//   });
// };

export default {
  loadTree,
  loadInvitationsByUsers,
  loadJoinedTreesByUsers,
  createTree,
  addFamilyMember,
  removeFamilyMember,
  updateTreeInviteStatus,
};

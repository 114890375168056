import { LOAD_POLL_SUCCESS, LOAD_POLL_FAIL } from "../actions/types";

const initialState = {
  polls: null,
  message: "",
  isLoading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_POLL_SUCCESS:
      return {
        ...state,
        polls: payload.data,
        message: payload.message,
        isLoading: false,
      };
    case LOAD_POLL_FAIL:
      return {
        ...state,
        polls: null,
        message: payload.message,
        isLoading: false,
      };
    default:
      return state;
  }
}

import { Box, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import MHeader from "./MHeader";
import MFooter from "./MFooter";
import "../css/mobile.scss";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserDetails } from "../../actions/auth";
import { LoadTree } from "../../actions/familyTree";

const MLayoutWrap = () => {
  const [pageTitle, setPageTitle] = useState("");
  const [activeTab, setActiveTab] = useState("");

  const globalState = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  const authToken = Cookies.get("auth-token"); // Retrieve the authentication token from the cookie

  useEffect(() => {
    if (authToken && globalState.user && !globalState.userDetails) {
      dispatch(getCurrentUserDetails());
      dispatch(LoadTree());
    }
  }, [authToken, globalState.user]);

  return (
    <VStack
      position={"relative"}
      height={"100vh"}
      overflow={"hidden"}
      bg={"#FFFCF1"}
      px={2}
      maxW={"500px"}
      margin={"auto"}
      gap={0}
    >
      <MHeader pageTitle={pageTitle} />
      <Box
        w={"full"}
        overflow={"auto"}
        height={"-webkit-fill-available"}
        paddingBottom={"80px"}
        bg={"#FFFCF1"}
      >
        {/* <Text fontSize={"lg"} color={"red"}>
          Token: {JSON.parse(authToken || null)?.token}
        </Text> */}
        <Outlet context={{ setPageTitle, setActiveTab }} />
      </Box>
      <Box
        w={"full"}
        background={"transparent"}
        position={"absolute"}
        bottom={0}
      >
        <MFooter activeTab={activeTab} />
      </Box>
    </VStack>
  );
};

export default MLayoutWrap;

const initialState = {
  // theme: localStorage.getItem("theme") || "light",
  theme: localStorage.getItem("chakra-ui-color-mode") || "light",
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_THEME":
      const newTheme = state.theme === "light" ? "dark" : "light";
      // localStorage.setItem("theme", newTheme);
      localStorage.setItem("chakra-ui-color-mode", newTheme);
      return {
        ...state,
        theme: newTheme,
      };
    default:
      return state;
  }
};

export default themeReducer;

//DEMO EVENTS DATA
export const eventData = [
  {
    id: 1,
    title: `9-9`,
    location: "Dhaka, Bangladesh",
    start: new Date(2023, 1, 9, 9, 0, 0),
    end: new Date(2023, 1, 9, 13, 0, 0),
  },
  {
    id: Math.random(),
    title: `9-10`,
    location: "Dhaka, Bangladesh",
    start: new Date(2023, 1, 9, 9, 0, 0),
    end: new Date(2023, 1, 10, 13, 0, 0),
  },
  {
    id: Math.random(),
    title: `9-11`,
    location: "Dhaka, Bangladesh",
    start: new Date(2023, 1, 9, 9, 0, 0),
    end: new Date(2023, 1, 11, 13, 0, 0),
  },
  {
    id: Math.random(),
    title: `9-19`,
    location: "Dhaka, Bangladesh",
    start: new Date(2023, 1, 9, 9, 0, 0),
    end: new Date(2023, 1, 19, 13, 0, 0),
  },
  {
    id: Math.random(),
    title: `9-9-1`,
    location: "Dhaka, Bangladesh",
    start: new Date(2023, 1, 9, 9, 0, 0),
    end: new Date(2023, 1, 9, 13, 0, 0),
  },
  {
    id: Math.random(),
    title: `Board meeting`,
    location: "Dhaka, Bangladesh",
    start: new Date(2023, 0, 9, 9, 0, 0),
    end: new Date(2023, 0, 9, 13, 0, 0),
  },
  {
    id: Math.random(),
    title: `Board meeting`,
    location: "Dhaka, Bangladesh",
    start: new Date(2023, 0, 9, 9, 0, 0),
    end: new Date(2023, 0, 9, 13, 0, 0),
  },
  {
    id: 2,
    title: "Team lead meeting",
    location: "Rajshahi, Bangladesh",
    start: new Date(2023, 2, 15, 9, 0, 0),
    end: new Date(2023, 2, 15, 13, 0, 0),
  },
  {
    id: 3,
    title: "Coxbazar Tour",
    location: "Coxbazar, Bangladesh",
    start: new Date(2023, 9, 30, 8, 30, 0),
    end: new Date(2023, 9, 30, 12, 30, 0),
  },
  {
    id: 4,
    title: "JoomShaper's Event",
    location: "Dhanmondi, Dhaka",
    start: new Date(2023, 10, 2, 8, 30, 0),
    end: new Date(2023, 10, 2, 12, 30, 0),
  },
];

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
// import UserContext from "./context/UserContext";

import { Provider } from "react-redux";
import store from "./stores/store";
import theme from "./Theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      {/* <React.StrictMode> */}
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <App />
      {/* </React.StrictMode> */}
    </ChakraProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

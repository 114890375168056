import { LOAD_ALBUM_SUCCESS, LOAD_ALBUM_FAIL } from "../actions/types";

const initialState = {
  albums: null,
  isLoading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_ALBUM_SUCCESS:
      return {
        ...state,
        albums: payload.data,
        message: payload.message,
        isLoading: false,
      };
    case LOAD_ALBUM_FAIL:
      return {
        ...state,
        albums: [],
        message: "Failed to load",
        isLoading: false,
      };
    default:
      return state;
  }
}

import { combineReducers } from "redux";
import auth from "./auth";
import familyRecipes from "./familyRecipes";
import fundCause from "./familyFundCause";
import familyPolls from "./familyPolls";
import familyEvents from "./familyEvents";
import message from "./message";
import events from "./reducer_events";
import familyTree from "./familyTree";
import messages from "./messages";
import familyGallery from "./familyGallery";
import appointments from "./appointments";
import frames from "./frames";
import theme from "./themeReducer";

const appReducer = combineReducers({
  auth,
  familyRecipes,
  familyPolls,
  fundCause,
  familyEvents,
  message,
  events,
  familyTree,
  messages,
  familyGallery,
  appointments,
  frames,
  theme,
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    // return appReducer(undefined, action);
    state = initialState;
  }
  return appReducer(state, action);
};

export default rootReducer;

import {
  LOAD_ALL_MY_EVENTS_SUCCESS,
  LOAD_ALL_MY_EVENTS_FAIL,
  LOAD_PREV_EVENTS_SUCCESS,
  LOAD_PREV_EVENTS_FAIL,
  LOAD_INV_EVENTS_SUCCESS,
  LOAD_INV_EVENTS_FAIL,
  LOAD_ALL_EVENTS_SUCCESS,
  LOAD_ALL_EVENTS_FAIL,
} from "../actions/types";

const initialState = {
  myEvents: null,
  allEvents: null,
  previous: null,
  invites: null,
  message: "",
  isLoading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_ALL_MY_EVENTS_SUCCESS:
      return {
        ...state,
        myEvents: payload.data,
        message: payload.message,
        isLoading: false,
      };
    case LOAD_ALL_MY_EVENTS_FAIL:
      return {
        ...state,
        myEvents: null,
        message: payload.message,
        isLoading: false,
      };
    case LOAD_PREV_EVENTS_SUCCESS:
      return {
        ...state,
        previous: payload.data,
        message: payload.message,
        isLoading: false,
      };
    case LOAD_PREV_EVENTS_FAIL:
      return {
        ...state,
        previous: null,
        message: payload.message,
        isLoading: false,
      };
    case LOAD_INV_EVENTS_SUCCESS:
      return {
        ...state,
        invites: payload.data,
        message: payload.message,
        isLoading: false,
      };
    case LOAD_INV_EVENTS_FAIL:
      return {
        ...state,
        invites: null,
        message: payload.message,
        isLoading: false,
      };
    case LOAD_ALL_EVENTS_SUCCESS:
      return {
        ...state,
        allEvents: payload.data,
        message: payload.message,
        isLoading: false,
      };
    case LOAD_ALL_EVENTS_FAIL:
      return {
        ...state,
        allEvents: [],
        message: payload.message,
        isLoading: false,
      };
    default:
      return state;
  }
}

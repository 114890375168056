import Cookies from "js-cookie";

export default function authHeader() {
  // const user = JSON.parse(localStorage.getItem("user"));
  const user = JSON.parse(Cookies.get("auth-token") || null);
  const token = user?.token;
  if (token) {
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    // return { 'x-access-token': user.accessToken };   // node express
  } else {
    return {};
  }
}

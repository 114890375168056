import {
  LOAD_FUND_CAUSE_SUCCESS,
  LOAD_FUND_CAUSE_FAIL,
  LOAD_FUND_TYPES_SUCCESS,
  LOAD_FUND_TYPES_FAIL,
} from "../actions/types";

const initialState = {
  funds: null,
  fundTypes: null,
  message: "",
  isLoading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_FUND_CAUSE_SUCCESS:
      return {
        ...state,
        funds: payload.data,
        message: payload.message,
        isLoading: false,
      };
    case LOAD_FUND_CAUSE_FAIL:
      return {
        ...state,
        funds: null,
        message: payload.message,
        isLoading: false,
      };
    case LOAD_FUND_TYPES_SUCCESS:
      return {
        ...state,
        fundTypes: payload.data,
        message: payload.message,
        isLoading: false,
      };
    case LOAD_FUND_TYPES_FAIL:
      return {
        ...state,
        fundTypes: null,
        message: payload.message,
        isLoading: false,
      };
    default:
      return state;
  }
}

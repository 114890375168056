import {
  LOAD_FAMILY_TREE_SUCCESS,
  LOAD_FAMILY_TREE_FAIL,
  INVITE_BY_USER_SUCCESS,
  INVITE_BY_USER_FAIL,
  JOINED_TREES_SUCCESS,
  JOINED_TREES_FAIL,
  SET_MESSAGE,
} from "./types";

import FamilyTreeServices from "../services/familyTree.service";

const responseFun = (response, dispatchObj = null) => {
  response.status = response.data.success;
  if (dispatchObj == null) {
    if (response.data.success == true) {
      response.message = response.data.message;
    } else {
      let err = response.data.message;
      err = typeof err == "object" ? err[Object.keys(err)[0]][0] : err;
      response.message = err;
    }
    return response;
  } else {
    const dispatch = dispatchObj.dispatch;
    if (response.data.success == true) {
      const message = response.data.message;
      dispatch({
        type: dispatchObj.SUCCESS,
        payload: { data: response.data.data, message: message },
      });

      dispatch({
        type: dispatchObj.SET_MESSAGE,
        payload: response.data.message,
      });
    } else {
      let err = response.data.message;
      err = typeof err == "object" ? err[Object.keys(err)[0]][0] : err;

      response.message = err;

      dispatch({
        type: dispatchObj.FAIL,
      });

      dispatch({
        type: dispatchObj.SET_MESSAGE,
        payload: err,
      });
      //   return response.data;
      return Promise.resolve();
    }
  }
};
const errorFun = (error, dispatchObj = null) => {
  if (dispatchObj == null) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return error.response;
  } else {
    const dispatch = dispatchObj.dispatch;
    dispatch({
      type: dispatchObj.FAIL,
    });
    dispatch({
      type: dispatchObj.SET_MESSAGE,
      payload: "Faild to Load",
    });
    return error.response.data;
    // return Promise.reject();
    // console.log(error.response.data);
  }
};

export const LoadTree = () => (dispatch) => {
  return FamilyTreeServices.loadTree().then(
    (response) =>{
      responseFun(response, {
        SUCCESS: LOAD_FAMILY_TREE_SUCCESS,
        FAIL: LOAD_FAMILY_TREE_FAIL,
        SET_MESSAGE,
        dispatch,
      })
    }
      ,
    (error) => {
      errorFun(error, {
        FAIL: LOAD_FAMILY_TREE_FAIL,
        SET_MESSAGE,
        dispatch,
      });
    }
  );
};

export const LoadInvitationsByUsers = () => async (dispatch) => {
  return await FamilyTreeServices.loadInvitationsByUsers().then(
    (response) =>
      responseFun(response, {
        SUCCESS: INVITE_BY_USER_SUCCESS,
        FAIL: INVITE_BY_USER_FAIL,
        SET_MESSAGE,
        dispatch,
      }),
    (error) => {
      errorFun(error, {
        FAIL: INVITE_BY_USER_FAIL,
        SET_MESSAGE,
        dispatch,
      });
    }
  );
};
export const LoadJoinedTreesByUsers = () => async (dispatch) => {
  return await FamilyTreeServices.loadJoinedTreesByUsers().then(
    (response) => {
      // console.log(response);
      responseFun(response, {
        SUCCESS: JOINED_TREES_SUCCESS,
        FAIL: JOINED_TREES_FAIL,
        SET_MESSAGE,
        dispatch,
      });
    },
    (error) => {
      errorFun(error, {
        FAIL: JOINED_TREES_FAIL,
        SET_MESSAGE,
        dispatch,
      });
    }
  );
};

export const CreateTree = (data) => async (dispatch) => {
  return await FamilyTreeServices.createTree(data).then(
    (response) => responseFun(response),
    (error) => errorFun(error)
  );
};
export const AddFamilyMember = (data) => async (dispatch) => {
  return await FamilyTreeServices.addFamilyMember(data).then(
    (response) => responseFun(response),
    (error) => errorFun(error)
  );
};
export const RemoveFamilyMember = (data) => async (dispatch) => {
  return await FamilyTreeServices.removeFamilyMember(data).then(
    (response) => responseFun(response),
    (error) => errorFun(error)
  );
};

export const UpdateTreeInviteStatus = (id, data) => async (dispatch) => {
  return await FamilyTreeServices.updateTreeInviteStatus(id, data).then(
    (response) => responseFun(response),
    (error) => errorFun(error)
  );
};

// export const UpdateRecipe = (id, formData, tags) => async (dispatch) => {
//   return await FamilyTreeServices.updateRecipyById(id, formData, tags).then(
//     (response) => {
//       response.status = response.data.success;
//       if (response.data.success == true) {
//         response.message = response.data.message;
//       } else {
//         let err = response.data.message;
//         err = typeof err == "object" ? err[Object.keys(err)[0]][0] : err;
//         response.message = err;
//       }
//       return response;
//     },
//     (error) => {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return error.response.data;
//     }
//   );
// };

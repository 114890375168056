import { LOAD_APPT_SUCCESS, LOAD_APPT_FAIL } from "../actions/types";

const initialState = {
  appts: null,
  isLoading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_APPT_SUCCESS:
      return {
        ...state,
        appts: payload.data,
        message: payload.message,
        isLoading: false,
      };
    case LOAD_APPT_FAIL:
      return {
        ...state,
        appts: [],
        message: "Failed to load",
        isLoading: false,
      };
    default:
      return state;
  }
}

import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTopOnMount from "./components/ScrollToTopOnMount";
import PrivateRoute from "./PrivateRoute";
import ValidateToken from "./PrivateRoute/ValidateMobileRoute";
import MLayoutWrap from "./mobile/components/MLayoutWrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./App.scss";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserDetails, setCookiesToStore } from "./actions/auth";
import Cookies from "js-cookie";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import PreLoading from "./components/PreLoading";

function App() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme?.theme);
  useEffect(() => {
    callAllBaseAPIs();
    NProgress.start();
  });

  useEffect(() => {
    // update chakra ui theme
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  const callAllBaseAPIs = () => {
    // let user = JSON.parse(localStorage.getItem("user"));
    let user = JSON.parse(Cookies.get("auth-token") || null);
    let token = user?.token;
    if (token && token !== "undefined") {
      dispatch(setCookiesToStore(user));
      dispatch(getCurrentUserDetails());
    }
  };
  return (
    <Router>
      <ScrollToTopOnMount />
      <Suspense fallback={<PreLoading />}>
        <Routes>
          <Route
            index
            path="/login"
            element={<LazyLogin callAllBaseAPIs={callAllBaseAPIs} />}
          />
          <Route
            path="/signup"
            element={<LazySignUp callAllBaseAPIs={callAllBaseAPIs} />}
          />
          <Route path="/resetPassword" element={<LazyResetPasswordForm />} />
          <Route path="/about" element={<LazyAboutUs />} />
          <Route path="/how-it-works" element={<LazyHowItWork />} />

          <Route element={<PrivateRoute />}>
            <Route path="/" element={<LazyMainPageWrap />}>
              <Route index path="homeview" element={<LazyHomeView />} />
              <Route path="edit" element={<LazyEditProfile />} />
              <Route path="familyrecipes" element={<LazyFamilyRecipes />} />
              <Route
                path="familyrecipes/:id"
                element={<LazyViewFamilyRecipe />}
              />
              <Route path="familyGallery" element={<LazyFamilyGallery />} />
              <Route
                path="familyGallery/edit/:id"
                element={<LazyEditFamilyGallery />}
              />
              <Route path="familyGallery/:id" element={<LazyViewAlbum />} />
              <Route
                path="messages/:id?/:user_id?"
                element={<LazyMessages />}
              />
              <Route
                path="editfamilyrecipe"
                element={<LazyEditFamilyRecipe />}
              />
              <Route path="familypolls" element={<LazyFamilyPolls />} />
              <Route path="familyblogs" element={<LazyFamilyBlogs />} />
              <Route path="familyCalendar" element={<LazyFamilyCalendar />} />
              <Route path="appointments" element={<LazyAppointments />} />
              <Route path="fundcause" element={<LazyFundCause />} />
              <Route path="editfundcause" element={<LazyEditFundCause />} />
              <Route path="donatefund" element={<LazyDonateFund />} />
              <Route path="donatemeal" element={<LazyDonateMeal />} />
              <Route path="groupfamilycall" element={<LazyGroupFamilyCall />} />
              <Route path="familyevents" element={<LazyFamilyEvents />} />
              <Route
                path="editfamilyevents"
                element={<LazyEditFamilyEvents />}
              />
              <Route path="notification" element={<LazyNotification />} />
              <Route path="yearlytimeline" element={<LazyYearlyTimeline />} />
              <Route path="frames" element={<LazyFrames />} />
            </Route>
            <Route path="familytree" element={<LazyFamilyTree />} />
            <Route path="profile/:id" element={<LazyProfileTimeline />} />
          </Route>

          <Route element={<ValidateToken />}>
            <Route path="/m" element={<MLayoutWrap />}>
              <Route index path="home" element={<LazyHomeMobile />} />
              <Route
                path="notification"
                element={<LazyMobileNotifications />}
              />
              <Route path="profile" element={<LazyMobileEditProfile />} />
              <Route path="messages" element={<LazyMobileMessage />} />
              <Route path="treeList" element={<LazyMobileTreeList />} />
              <Route path="familyGallery" element={<LazyMobileAlbums />} />
              <Route
                path="familyGallery/:id"
                element={<LazyMobileViewAlbums />}
              />
              <Route path="familyrecipes" element={<LazyMobileRecipes />} />
              <Route
                path="familyrecipes/:id"
                element={<LazyViewFamilyRecipe />}
              />
              <Route path="familyevents" element={<LazyMobileEvents />} />
              <Route path="fundcause" element={<LazyMobileFundCause />} />
              <Route path="calendar" element={<LazyMobileCalendar />} />
              <Route path="donatefund" element={<LazyDonateFund />} />
              <Route path="donatemeal" element={<LazyDonateMeal />} />
              <Route path="familypolls" element={<LazyMobilePolls />} />
              <Route path="yearlytimeline" element={<LazyMobileTimeline />} />
              <Route path="frames" element={<LazyMobileFrames />} />
            </Route>
            <Route path="m/messages/:id" element={<LazyMobileMessageView />} />
            <Route path="m/treeList/:id" element={<LazyMobileTreeView />} />
          </Route>
          <Route path="m/notfound" element={<LazyMobileNotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;



// Lazy loading components
const LazyLogin = React.lazy(() => import("./pages/Login"));
const LazySignUp = React.lazy(() => import("./pages/SignUp"));
const LazyHomeView = React.lazy(() => import("./pages/HomeView"));
const LazyFamilyTree = React.lazy(() => import("./pages/FamilyTree"));
const LazyFundCause = React.lazy(() => import("./pages/FundCause"));
const LazyDonateFund = React.lazy(() => import("./pages/DonateFund"));
const LazyGroupFamilyCall = React.lazy(() => import("./pages/GroupFamilyCall"));
const LazyFamilyEvents = React.lazy(() => import("./pages/FamilyEvents"));
const LazyFamilyRecipes = React.lazy(() => import("./pages/FamilyRecipes"));
const LazyFamilyGallery = React.lazy(() => import("./pages/FamilyGallery"));
const LazyEditFamilyGallery = React.lazy(() =>
  import("./components/EditFamilyGallery")
);
const LazyViewAlbum = React.lazy(() => import("./pages/ViewAlbum"));
const LazyMessages = React.lazy(() => import("./pages/Messages"));
const LazyEditProfile = React.lazy(() => import("./pages/EditProfile"));
const LazyViewFamilyRecipe = React.lazy(() =>
  import("./components/ViewFamilyRecipe")
);
const LazyFamilyPolls = React.lazy(() => import("./pages/FamilyPolls"));
const LazyFamilyCalendar = React.lazy(() => import("./pages/FamilyCalendar"));
const LazyYearlyTimeline = React.lazy(() => import("./pages/YearlyTimeline"));
const LazyEditFundCause = React.lazy(() =>
  import("./components/EditFundCause")
);



const LazyNotification = React.lazy(() => import("./components/Notification"));
const LazyEditFamilyRecipe = React.lazy(() =>
  import("./components/EditFamilyRecipe")
);
const LazyEditFamilyEvents = React.lazy(() =>
  import("./components/EditFamilyEvents")
);
const LazyMainPageWrap = React.lazy(() => import("./pages/MainPageWrap"));
const LazyFamilyBlogs = React.lazy(() => import("./pages/FamilyBlogs"));
const LazyProfileTimeline = React.lazy(() => import("./pages/ProfileTimeline"));
const LazyDonateMeal = React.lazy(() => import("./pages/DonateMeal"));
const LazyAppointments = React.lazy(() => import("./pages/Appointments"));
const LazyResetPasswordForm = React.lazy(() =>
  import("./pages/ResetPasswordForm")
);
const LazyFrames = React.lazy(() => import("./pages/Frames"));
const LazyAboutUs = React.lazy(() => import("./pages/AboutUs"));
const LazyHowItWork = React.lazy(() => import("./pages/HowItWork"));

// Mobile components
const LazyHomeMobile = React.lazy(() => import("./mobile/pages/Home"));
const LazyMobileNotifications = React.lazy(() =>
  import("./mobile/pages/MNotifications")
);
const LazyMobileMessage = React.lazy(() => import("./mobile/pages/MMessage"));
const LazyMobileMessageView = React.lazy(() =>
  import("./mobile/pages/MMessageView")
);
const LazyMobileNotFound = React.lazy(() => import("./mobile/pages/MNotFound"));
const LazyMobileTreeList = React.lazy(() => import("./mobile/pages/MTreeList"));
const LazyMobileTreeView = React.lazy(() => import("./mobile/pages/MTreeView"));
const LazyMobileEditProfile = React.lazy(() =>
  import("./mobile/pages/MEditProfile")
);
const LazyMobileAlbums = React.lazy(() => import("./mobile/pages/MAlbums"));
const LazyMobileViewAlbums = React.lazy(() =>
  import("./mobile/pages/MViewAlbums")
);
const LazyMobileRecipes = React.lazy(() => import("./mobile/pages/MRecipes"));
const LazyMobileEvents = React.lazy(() => import("./mobile/pages/MEvents"));
const LazyMobileFundCause = React.lazy(() =>
  import("./mobile/pages/MFundCause")
);
const LazyMobilePolls = React.lazy(() => import("./mobile/pages/MPolls"));
const LazyMobileTimeline = React.lazy(() => import("./mobile/pages/MTimeline"));
const LazyMobileFrames = React.lazy(() => import("./mobile/pages/MFrames"));
const LazyMobileCalendar = React.lazy(() => import("./mobile/pages/MCalendar"));

import {
  Box,
  Grid,
  GridItem,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import homeIcon from "../images/home_icon.svg";
import messagesIcon from "../images/messages-icon.svg";
import notificationIcon from "../images/notification-icon.svg";
import userIcon from "../images/user-icon.svg";
import familyIcon from "../images/family-icon.svg";
import { Link } from "react-router-dom";
import { SetNav } from "../../actions/auth";
import { useDispatch } from "react-redux";
const MFooter = (props) => {
  const dispatch = useDispatch();

  const handleLink = (link) => {
    dispatch(SetNav(link));
  };

  return (
    <HStack
      className="bottom_bar"
      w={"full"}
      px={1}
      py={4}
      bg={"#1E1E1E"}
      justifyContent={"center"}
      borderTopLeftRadius={"40px"}
      borderTopRightRadius={"40px"}
    >
      <Grid templateColumns="repeat(5, 1fr)" gap={2}>
        <GridItem w="100%" h="10" className="bottom_bar_item">
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={1}
            py={1}
          >
            <Link
              to={"/m/home"}
              onClick={() => handleLink("/m/home")}
              style={{ textAlign: "-webkit-center" }}
            >
              <Image
                src={homeIcon}
                maxW={"30px"}
                mb={1}
                style={{
                  filter:
                    props?.activeTab == "dashboard"
                      ? "invert(49%) sepia(91%) saturate(479%) hue-rotate(357deg) brightness(135%) contrast(88%)"
                      : "",
                  //   "invert(71%) sepia(86%) saturate(475%) hue-rotate(356deg) brightness(103%) contrast(94%)",
                }}
              />
              <Text
                bgGradient={"linear-gradient(90deg, #F7CA15 0%, #F2994A 100%)"}
                bgClip="text"
                color={
                  props?.activeTab == "dashboard" ? "transparent" : "white"
                }
                fontSize="12px"
                fontWeight={400}
                lineHeight="16px"
                letterSpacing="0px"
                textAlign="left"
              >
                Home
              </Text>
            </Link>
          </Box>
        </GridItem>
        <GridItem w="100%" h="10" className="bottom_bar_item">
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={1}
            py={1}
          >
            <Link
              to={"/m/messages"}
              onClick={() => handleLink("/m/messages")}
              style={{ textAlign: "-webkit-center" }}
            >
              <Image
                src={messagesIcon}
                maxW={"30px"}
                mb={1}
                style={{
                  filter:
                    props?.activeTab == "messages"
                      ? "invert(49%) sepia(91%) saturate(479%) hue-rotate(357deg) brightness(135%) contrast(88%)"
                      : "",
                }}
              />
              <Text
                bgGradient={"linear-gradient(90deg, #F7CA15 0%, #F2994A 100%)"}
                bgClip="text"
                color={props?.activeTab == "messages" ? "transparent" : "white"}
                fontSize="12px"
                fontWeight={400}
                lineHeight="16px"
                letterSpacing="0px"
                textAlign="left"
              >
                Messages
              </Text>
            </Link>
          </Box>
        </GridItem>
        <GridItem w="100%" h="10" className="bottom_bar_item family_icon_item">
          <Box
            style={{
              transform: "translateY(-35px)",
              padding: "0px",
              margin: "0px",
            }}
          >
            <Link
              to={"/m/treeList"}
              onClick={() => handleLink("/m/treeList")}
              style={{ textAlign: "-webkit-center" }}
            >
              <Box borderRadius={"100%"} p={2} bg={"#424242"}>
                <Box
                  borderRadius={"100%"}
                  p={2}
                  style={{
                    background: "#F7CA15",
                  }}
                >
                  <Box
                    borderRadius={"100%"}
                    p={2}
                    style={{
                      background:
                        "linear-gradient(90deg, #F7CA15 0%, #F2994A 100%),linear-gradient(0deg, #F7CA15, #F7CA15)",
                    }}
                  >
                    <Image
                      src={familyIcon}
                      maxW={"30px"}
                      className="family_icon"
                      style={{
                        filter: "",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Link>
          </Box>
        </GridItem>
        <GridItem w="100%" h="10" className="bottom_bar_item">
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={1}
            py={1}
          >
            <Link
              to={"/m/notification"}
              onClick={() => handleLink("/m/notification")}
              style={{ textAlign: "-webkit-center" }}
            >
              <Image
                src={notificationIcon}
                maxW={"30px"}
                mb={1}
                style={{
                  filter:
                    props?.activeTab == "notification"
                      ? "invert(49%) sepia(91%) saturate(479%) hue-rotate(357deg) brightness(135%) contrast(88%)"
                      : "",
                }}
              />
              <Text
                bgGradient={"linear-gradient(90deg, #F7CA15 0%, #F2994A 100%)"}
                bgClip="text"
                color={
                  props?.activeTab == "notification" ? "transparent" : "white"
                }
                fontSize="12px"
                fontWeight={400}
                lineHeight="16px"
                letterSpacing="0px"
                textAlign="left"
              >
                Notifications
              </Text>
            </Link>
          </Box>
        </GridItem>
        <GridItem w="100%" h="10" className="bottom_bar_item">
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={1}
            py={1}
          >
            <Link
              to={"/m/profile"}
              onClick={() => handleLink("/m/profile")}
              style={{ textAlign: "-webkit-center" }}
            >
              <Image
                src={userIcon}
                maxW={"30px"}
                mb={1}
                style={{
                  filter:
                    props?.activeTab == "profile"
                      ? "invert(49%) sepia(91%) saturate(479%) hue-rotate(357deg) brightness(135%) contrast(88%)"
                      : "",
                }}
              />
              <Text
                bgGradient={"linear-gradient(90deg, #F7CA15 0%, #F2994A 100%)"}
                bgClip="text"
                color={props?.activeTab == "profile" ? "transparent" : "white"}
                fontSize="12px"
                fontWeight={400}
                lineHeight="16px"
                letterSpacing="0px"
                textAlign="left"
              >
                Profile
              </Text>
            </Link>
          </Box>
        </GridItem>
      </Grid>
    </HStack>
  );
};

export default MFooter;

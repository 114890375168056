import Cookies from "js-cookie";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ValidateToken = () => {
  // const token = Cookies.get("auth-token"); // Retrieve the authentication token from the cookie
  let user = JSON.parse(Cookies.get("auth-token") || null);
  let token = user?.token;

  if (token) {
    return <Outlet />;
  } else {
    return <Navigate to="/m/notfound" />;
    // return <Navigate to="/m/1" />;
  }
};
export default ValidateToken;

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  LOAD_ALL_USERS_SUCCESS,
  LOAD_ALL_USERS_FAIL,
  CHANGE_NAV,
  LOAD_USER_DETAILS_SUCCESS,
  LOAD_USER_DETAILS_FAIL,
} from "./types";

import AuthService from "../services/auth.service";

export const register = (userDetails) => (dispatch) => {
  return AuthService.register(userDetails)
    .then(
      (response) => {
        console.log(response.success);

        if (response.success === true) {
          const message = response.message;
          dispatch({
            type: REGISTER_SUCCESS,
            payload: { user: response, message: message },
          });

          dispatch({
            type: SET_MESSAGE,
            payload: response.message,
          });
        } else {
          let err = response.message;
          err = typeof err === "object" ? err[Object.keys(err)[0]][0] : err;

          response.message = err;

          dispatch({
            type: REGISTER_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: err,
          });

          return response;
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: REGISTER_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((e) => {
      dispatch({
        type: REGISTER_FAIL,
        payload: "Something went wrong",
      });
    });
};

export const login = (userDetails) => (dispatch) => {
  return AuthService.login(userDetails).then(
    (response) => {
      if (response.success === true) {
        const message = response.message;
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: response, message: message },
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.message,
        });
      } else {
        let err = response.message;
        err = typeof err === "object" ? err[Object.keys(err)[0]][0] : err;
        console.log("msg ", err);

        response.message = err;

        dispatch({
          type: LOGIN_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: err,
        });

        return response;
      }
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const forgotPassword = (userDetails) => (dispatch) => {
  return AuthService.forgotpassword(userDetails).then(
    (response) => {
      return response;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  );
};
export const resetPassword = (obj) => (dispatch) => {
  return AuthService.ResetPassword(obj).then(
    (response) => {
      return response;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return message;
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
    payload: null,
  });
  return true;
};

export const LoadAllNotifications = () => async (dispatch) => {
  return await AuthService.loadNotifications();
};
export const MarkAsReadNotification = (id) => async (dispatch) => {
  return await AuthService.markAsReadNotification(id);
};

export const LoadAllUsers = () => async (dispatch) => {
  return await AuthService.loadAllUsers()
    .then(
      (response) => {
        if (response.data.success === true) {
          const message = response.data.message;
          dispatch({
            type: LOAD_ALL_USERS_SUCCESS,
            payload: { data: response.data.data, message: message },
          });

          dispatch({
            type: SET_MESSAGE,
            payload: response.data.message,
          });
        } else {
          let err = response.data.message;
          err = typeof err === "object" ? err[Object.keys(err)[0]][0] : err;

          response.message = err;

          dispatch({
            type: LOAD_ALL_USERS_FAIL,
          });

          dispatch({
            type: SET_MESSAGE,
            payload: err,
          });

          //   return response.data;
          return Promise.resolve();
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: LOAD_ALL_USERS_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        // return error.response.data
        return Promise.reject();
      }
    )
    .catch((e) => {
      dispatch({
        type: LOAD_ALL_USERS_FAIL,
        payload: "Something went wrong",
      });
    });
};
export const getCurrentUserDetails = () => async (dispatch) => {
  return await AuthService.getUserDetails()
    .then(
      (response) => {
        // console.log("current user details response", response);
        if (response.data?.id) {
          dispatch({
            type: LOAD_USER_DETAILS_SUCCESS,
            payload: { data: response.data, message: "Successfully Fetched" },
          });
        }
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: LOAD_USER_DETAILS_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        // return error.response.data
        return Promise.reject();
      }
    )
    .catch((e) => {
      dispatch({
        type: LOAD_USER_DETAILS_FAIL,
        payload: "Something went wrong",
      });
    });
};
export const UpdateUserDetails = (data) => async (dispatch) => {
  return await AuthService.updateUserDetails(data).then(
    (response) => {
      // console.log("current user details response", response);
      return response.data;
    },
    (error) => {
      return error.response.data;
    }
  );
};
export const ChangePassword = (data) => async (dispatch) => {
  return await AuthService.changePassword(data).then(
    (response) => {
      // console.log("current user details response", response);
      return response.data;
    },
    (error) => {
      return error.response.data;
    }
  );
};
export const UpdateTreeAdmins = (data) => async (dispatch) => {
  return await AuthService.updateTreeAdmins(data).then(
    (response) => {
      // console.log("current user details response", response);
      return response.data;
    },
    (error) => {
      return error.response.data;
    }
  );
};

export const SetNav = (link) => (dispatch) => {
  dispatch({
    type: CHANGE_NAV,
    payload: link,
  });
};
export const setCookiesToStore = (tokenObj) => (dispatch) => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: { user: { data: tokenObj }, message: "Cookies Set Successfully" },
  });
};
export const GetTimelineData = (id) => async (dispatch) => {
  return await AuthService.getUserTimelineData(id).then(
    (response) => {
      if (response.data.success == true) {
        response.message = response.data.message;
      } else {
        let err = response.data.message;
        err = typeof err == "object" ? err[Object.keys(err)[0]][0] : err;
        response.message = err;
      }
      return response;
    },
    (error) => {
      return error.response;
    }
  );
};
export const CreateTimelinePost = (formData) => async (dispatch) => {
  return await AuthService.createUserTimelinePost(formData).then(
    (response) => {
      if (response.data.success == true) {
        response.message = response.data.message;
      } else {
        let err = response.data.message;
        err = typeof err == "object" ? err[Object.keys(err)[0]][0] : err;
        response.message = err;
      }
      return response;
    },
    (error) => {
      return error.response;
    }
  );
};
export const UpdateTimelinePost = (id, formData) => async (dispatch) => {
  return await AuthService.updateUserTimelinePost(id, formData).then(
    (response) => {
      if (response.data.success == true) {
        response.message = response.data.message;
      } else {
        let err = response.data.message;
        err = typeof err == "object" ? err[Object.keys(err)[0]][0] : err;
        response.message = err;
      }
      return response;
    },
    (error) => {
      return error.response;
    }
  );
};

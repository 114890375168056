import {
  LOAD_FRAME_SUCCESS,
  LOAD_FRAME_FAIL,
  LOAD_ACCESS_FRAME_FAIL,
  LOAD_ACCESS_FRAME_SUCCESS,
} from "../actions/types";

const initialState = {
  list: null,
  share_frames: null,
  isLoading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_FRAME_SUCCESS:
      return {
        ...state,
        list: payload.data,
        message: payload.message,
        isLoading: false,
      };
    case LOAD_FRAME_FAIL:
      return {
        ...state,
        list: [],
        message: "Failed to load",
        isLoading: false,
      };
    case LOAD_ACCESS_FRAME_SUCCESS:
      return {
        ...state,
        share_frames: payload.data,
        message: payload.message,
        isLoading: false,
      };
    case LOAD_ACCESS_FRAME_FAIL:
      return {
        ...state,
        share_frames: [],
        message: "Failed to load",
        isLoading: false,
      };
    default:
      return state;
  }
}

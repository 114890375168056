import Cookies from "js-cookie";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

// const PrivateRoute = ({ element: Element }) => {
//   // let user = JSON.parse(localStorage.getItem("user"));
//   let user = JSON.parse(Cookies.get("auth-token") || null);

//   let token = user?.token;
//   return token && token !== "undefined" ? (
//     <Element />
//   ) : (
//     <Navigate to="../login" replace />
//   );
// };

// not working
const PrivateRoute = () => {
  let user = JSON.parse(Cookies.get("auth-token") || null);
  let token = user?.token;
  if (token && token !== "undefined") {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const CHANGE_NAV = "CHANGE_NAV";


export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";


export const LOAD_RECIPES_SUCCESS = "LOAD_RECIPES_SUCCESS";
export const LOAD_RECIPES_FAIL = "LOAD_RECIPES_FAIL";


export const LOAD_UOM_SUCCESS = "LOAD_UOM_SUCCESS";
export const LOAD_UOM_FAIL = "LOAD_UOM_FAIL";

export const LOAD_POLL_SUCCESS = "LOAD_POLL_SUCCESS";
export const LOAD_POLL_FAIL = "LOAD_POLL_FAIL";


export const LOAD_ALL_USERS_SUCCESS = "LOAD_ALL_USERS_SUCCESS";
export const LOAD_ALL_USERS_FAIL = "LOAD_ALL_USERS_FAIL";

export const LOAD_USER_DETAILS_SUCCESS = "LOAD_USER_DETAILS_SUCCESS";
export const LOAD_USER_DETAILS_FAIL = "LOAD_USER_DETAILS_FAIL";

export const LOAD_FUND_CAUSE_SUCCESS = "LOAD_FUND_CAUSE_SUCCESS";
export const LOAD_FUND_CAUSE_FAIL = "LOAD_FUND_CAUSE_FAIL";


export const LOAD_FUND_TYPES_SUCCESS = "LOAD_FUND_TYPES_SUCCESS";
export const LOAD_FUND_TYPES_FAIL = "LOAD_FUND_TYPES_FAIL";


export const LOAD_ALL_MY_EVENTS_SUCCESS = "LOAD_ALL_MY_EVENTS_SUCCESS";
export const LOAD_ALL_MY_EVENTS_FAIL = "LOAD_ALL_MY_EVENTS_FAIL";

export const LOAD_PREV_EVENTS_SUCCESS = "LOAD_PREV_EVENTS_SUCCESS";
export const LOAD_PREV_EVENTS_FAIL = "LOAD_PREV_EVENTS_FAIL";

export const LOAD_INV_EVENTS_SUCCESS = "LOAD_INV_EVENTS_SUCCESS";
export const LOAD_INV_EVENTS_FAIL = "LOAD_INV_EVENTS_FAIL";


export const LOAD_FAMILY_TREE_SUCCESS = "LOAD_FAMILY_TREE_SUCCESS";
export const LOAD_FAMILY_TREE_FAIL = "LOAD_FAMILY_TREE_FAIL";

export const LOAD_CHATS_SUCCESS = "LOAD_CHATS_SUCCESS";
export const LOAD_CHATS_FAIL = "LOAD_CHATS_FAIL";


export const INVITE_BY_USER_SUCCESS = "INVITE_BY_USER_SUCCESS";
export const INVITE_BY_USER_FAIL = "INVITE_BY_USER_FAIL";

export const JOINED_TREES_SUCCESS = "JOINED_TREES_SUCCESS";
export const JOINED_TREES_FAIL = "JOINED_TREES_FAIL";

export const LOAD_ALL_EVENTS_SUCCESS = "LOAD_ALL_EVENTS_SUCCESS";
export const LOAD_ALL_EVENTS_FAIL = "LOAD_ALL_EVENTS_FAIL";

export const LOAD_ALBUM_SUCCESS = "LOAD_ALBUM_SUCCESS";
export const LOAD_ALBUM_FAIL = "LOAD_ALBUM_FAIL";

export const LOAD_FRAME_SUCCESS = "LOAD_FRAME_SUCCESS";
export const LOAD_FRAME_FAIL = "LOAD_FRAME_FAIL";

export const LOAD_ACCESS_FRAME_SUCCESS = "LOAD_ACCESS_FRAME_SUCCESS";
export const LOAD_ACCESS_FRAME_FAIL = "LOAD_ACCESS_FRAME_FAIL";

export const LOAD_APPT_SUCCESS = "LOAD_APPT_SUCCESS";
export const LOAD_APPT_FAIL = "LOAD_APPT_FAIL";

export const SET_CHAT_HEADS = "SET_CHAT_HEADS";



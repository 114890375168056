import { Box, Image, Spinner } from "@chakra-ui/react";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import React, { useEffect } from "react";
// import loadingGif from "../assets/images/preloading.gif";
import { PRIMARY_COLOR } from "../util/constants";

const PreLoading = () => {
  useEffect(() => {
    NProgress.start();
    return () => {
      NProgress.done();
    };
  }, []);
  return (
    <Box
      w={"100vw"}
      h={"100vh"}
      overflow={"hidden"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      zIndex={999}
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color={PRIMARY_COLOR}
        size="lg"
      />
      {/* <Image src={loadingGif}  /> */}
    </Box>
  );
};

export default PreLoading;

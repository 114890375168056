import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import { AiOutlineSetting } from "react-icons/ai";
import { BiMenu } from "react-icons/bi";
import {
  BsCalendar3,
  BsChevronDown,
  BsChevronRight,
  BsPencil,
  BsTree,
} from "react-icons/bs";
import {
  MdCleanHands,
  MdOutlineCalendarToday,
  MdOutlineEditCalendar,
  MdOutlineEmojiFlags,
  MdOutlineEvent,
  MdOutlineInsertPhoto,
  MdOutlineMailOutline,
  MdPoll,
} from "react-icons/md";
import { HiOutlineCake, HiOutlinePhotograph } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { SetNav, getCurrentUserDetails, logout } from "../../actions/auth";
import { Link, useNavigate } from "react-router-dom";
import { PRIMARY_COLOR } from "../../util/constants";
import profile from "../images/profile.png";
import { useSwipeable } from "react-swipeable";
import BaseUrlImage from "../../util/BaseUrlImage";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";

const Links = [
  {
    name: "Family Tree",
    url: "/m/treeList",
    tag: "familytree",
    icon: <BsTree />,
  },
  {
    name: "Gallery",
    url: "/m/familyGallery",
    tag: "",
    icon: <HiOutlinePhotograph />,
  },
  {
    name: "Recipes",
    url: "/m/familyrecipes",
    tag: "familyrecipes",
    icon: <HiOutlineCake />,
  },
  {
    name: "Events",
    url: "/m/familyevents",
    tag: "familyevents",
    icon: <MdOutlineEvent />,
  },
  {
    name: "Being Supportive",
    url: "/m/fundcause",
    tag: "fundcause",
    icon: <MdCleanHands />,
  },
  {
    name: "Polls",
    url: "/m/familypolls",
    tag: "familypolls",
    icon: <MdPoll />,
  },
  {
    name: "Calendar",
    url: "/m/calendar",
    tag: "calendar",
    icon: <MdOutlineEditCalendar />,
    // subMenu: [
    //   {
    //     name: "Events",
    //     url: "/m/familyCalendar",
    //     tag: "events",
    //     icon: <MdOutlineCalendarToday />,
    //   },
    //   {
    //     name: "Appointments",
    //     url: "/m/appointments",
    //     tag: "appointments",
    //     icon: <BsCalendar3 />,
    //   },
    // ],
  },
  {
    name: "Message",
    url: "/m/messages",
    tag: "",
    icon: <MdOutlineMailOutline />,
  },
  {
    name: "Timeline",
    url: "/m/yearlytimeline",
    tag: "yearlytimeline",
    icon: <MdOutlineEmojiFlags />,
  },
  {
    name: "Frames",
    url: "/m/frames",
    tag: "frames",
    icon: <MdOutlineInsertPhoto />,
  },
];

const LeftIcon = ({ data }) => {
  return (
    <IconButton bg={"transparent"} onClick={data?.action} icon={data?.icon} />
  );
};

const MHeader = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [active, setActive] = useState("");
  const [dropdown, setDropdown] = useState({});
  const [userDetails, setUserDetails] = useState({});

  const globalState = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (globalState.userDetails) {
      setUserDetails(globalState.userDetails);
    } else {
      dispatch(getCurrentUserDetails());
    }
  }, [globalState?.userDetails]);

  useEffect(() => {
    setActive(window.location.pathname);
  }, [globalState.activeNav]);

  const handleLink = (link) => {
    dispatch(SetNav(link));
    onClose();
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      onClose();
    },
  });
  // const handleLogOut = useCallback(async () => {
  //   try {
  //     await dispatch(logout());
  //     navigate("/m/1");
  //   } catch (error) {
  //     console.error("Logout failed:", error);
  //   }
  // }, [dispatch, navigate]);

  const handleLogOut = useCallback(() => {
    dispatch(logout());
    Cookies.remove("auth-token");
    navigate("/m/notfound");
  }, [dispatch]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
      />
      <HStack w={"full"} py={2} justifyContent={"space-between"}>
        <LeftIcon
          data={
            props?.leftIcon || {
              icon: <BiMenu fontSize={"26.59px"} />,
              action: onOpen,
            }
          }
        />
        <Text
          fontSize={"md"}
          fontWeight={700}
          lineHeight={"26.59px"}
          color={"#182A53"}
        >
          {props.pageTitle}
        </Text>

        <Flex>
          <Menu>
            <MenuButton
              w={"7"}
              bg={"transparent"}
              as={IconButton}
              size={"sm"}
              _hover={{ bg: "#E2E8F0" }}
              icon={<AiOutlineSetting fontSize={"26.59px"} color="#848484" />}
            />
            <MenuList>
              <MenuItem
                fontFamily={"Manrope"}
                fontWeight={"500"}
                onClick={handleLogOut}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent
          {...handlers}
          style={{
            borderTopRightRadius: "20px",
            borderBottomRightRadius: "20px",
            maxWidth: "290px",
          }}
        >
          <DrawerHeader>
            <DrawerCloseButton />
            <HStack w={"full"} p={2}>
              <Box className="profile" pr={2}>
                <Box position={"relative"}>
                  <Avatar
                    height={"63px"}
                    width={"63px"}
                    title="Profile"
                    // src={profile}
                    src={
                      userDetails?.profile_photo_path
                        ? BaseUrlImage + userDetails.profile_photo_path
                        : userDetails.profile_photo_url
                    }
                  />
                  <IconButton
                    right={-1}
                    bottom={-1}
                    position={"absolute"}
                    size={"xs"}
                    border={"2px solid white"}
                    isRound={true}
                    variant="solid"
                    bg={PRIMARY_COLOR}
                    icon={<BsPencil fontSize={"10px"} color="#FFFFFF" />}
                  />
                </Box>
              </Box>
              <VStack py={4} gap={2} alignItems={"flex-start"}>
                <Text fontSize={"22px"} lineHeight={"24.59px"} fontWeight={800}>
                  {/* Chris Selby */}
                  {userDetails?.name}
                </Text>
                <Text fontSize={"14px"} lineHeight={"16.39px"} fontWeight={600}>
                  {userDetails?.family_name || ""}
                </Text>
              </VStack>
            </HStack>
            <Divider border={"1px solid #DADADA"} m={"auto"} />
          </DrawerHeader>
          <DrawerBody textAlign={"center"}>
            <VStack w={"full"} align={"flex-start"} px={2} gap={4}>
              {Links.map((obj, i) => {
                return (
                  <VStack w={"full"} key={i} alignItems={"flex-start"}>
                    {obj.subMenu && obj.subMenu.length > 0 ? (
                      <>
                        <HStack
                          w={"full"}
                          style={{
                            cursor: "pointer",
                            fontFamily: "Manrope",
                            fontWeight: "800",
                          }}
                        >
                          <Button
                            leftIcon={obj?.icon != "" ? obj.icon : <></>}
                            rightIcon={
                              dropdown[obj.tag] ? (
                                <BsChevronDown />
                              ) : (
                                <BsChevronRight />
                              )
                            }
                            fontSize={"14px"}
                            lineHeight={"19.12px"}
                            color={"#182A53"}
                            w={"fit-content"}
                            justifyContent={"flex-start"}
                            onClick={() =>
                              setDropdown({
                                ...dropdown,
                                [obj.tag]: !dropdown[obj.tag],
                              })
                            }
                            variant="link"
                          >
                            {obj.name}
                          </Button>
                        </HStack>
                        <Collapse in={dropdown[obj.tag]} animateOpacity>
                          <VStack w={"full"} pl={2}>
                            {obj.subMenu.map((list, index) => {
                              return (
                                <HStack
                                  key={index}
                                  w={"full"}
                                  className={
                                    list.url == active ? "active__menu" : ""
                                  }
                                  style={{
                                    cursor: "pointer",
                                    fontFamily: "Manrope",
                                    fontWeight: "800",
                                  }}
                                >
                                  <Button
                                    leftIcon={
                                      list?.icon != "" ? list.icon : <></>
                                    }
                                    as={Link}
                                    to={"#"} //obj.url
                                    onClick={() => handleLink(list.url)}
                                    variant="link"
                                    className={
                                      list.url == active ? "active__menu" : ""
                                    }
                                    fontSize={"14px"}
                                    lineHeight={"19.12px"}
                                    w={"fit-content"}
                                    color={
                                      obj.url == active ? "#F7CA15" : "#182A53"
                                    }
                                    justifyContent={"flex-start"}
                                  >
                                    {list.name}
                                  </Button>
                                </HStack>
                              );
                            })}
                          </VStack>
                        </Collapse>
                      </>
                    ) : (
                      <HStack
                        w={"full"}
                        style={{
                          cursor: "pointer",
                          fontFamily: "Manrope",
                          fontWeight: "800",
                        }}
                      >
                        <Button
                          leftIcon={obj?.icon != "" ? obj.icon : <></>}
                          fontSize={"14px"}
                          lineHeight={"19.12px"}
                          w={"fit-content"}
                          justifyContent={"flex-start"}
                          as={Link}
                          onClick={() => handleLink(obj.url)}
                          color={obj.url == active ? "#F7CA15" : "#182A53"}
                          to={obj.url} //obj.url
                          variant="link"
                        >
                          {obj.name}
                        </Button>
                      </HStack>
                    )}
                  </VStack>
                );
              })}
            </VStack>
          </DrawerBody>
          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MHeader;

import {
  LOAD_CHATS_SUCCESS,
  LOAD_CHATS_FAIL,
  SET_CHAT_HEADS,
} from "../actions/types";

const initialState = {
  chatHeads: null,
  chatList: null,
  isLoading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_CHATS_SUCCESS:
      return {
        ...state,
        chatHeads: payload.data,
        chatList: payload.data,
        message: payload.message,
        isLoading: false,
      };
    case LOAD_CHATS_FAIL:
      return {
        ...state,
        chatHeads: [],
        chatList: [],
        message: "Failed to load",
        isLoading: false,
      };
    case SET_CHAT_HEADS:
      return {
        ...state,
        chatList: payload,
      };
    default:
      return state;
  }
}

import {
  LOAD_RECIPES_SUCCESS,
  LOAD_RECIPES_FAIL,
  LOAD_UOM_SUCCESS,
  LOAD_UOM_FAIL,
} from "../actions/types";

const initialState = {
  recipes: null,
  message: "",
  isLoading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_UOM_SUCCESS:
      return {
        ...state,
        uomlist: payload.data,
        message: payload.message,
      };
    case LOAD_UOM_FAIL:
      return {
        ...state,
        uomlist: payload.data,
        message: payload.message,
      };
    case LOAD_RECIPES_SUCCESS:
      return {
        ...state,
        recipes: payload.data,
        message: payload.message,
        isLoading: false,
      };
    case LOAD_RECIPES_FAIL:
      return {
        ...state,
        recipes: null,
        message: payload.message,
        isLoading: false,
      };
    default:
      return state;
  }
}
